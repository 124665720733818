<template>
  <div>
    <!-- Contenu de la vue Home -->
    <h1>Page not found</h1>
  </div>
</template>

<script src="./notFound.ts"></script>
<style src="./notFound.css"></style>

