import { defineComponent } from "vue";

export default defineComponent({
    name: "Header",
    data() {
        const showMobileMenu: boolean = false;
        return {
            showMobileMenu,
        };
    },
    methods: {
        showMenu() {
            this.showMobileMenu = !this.showMobileMenu;
        },
    },
});