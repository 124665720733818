
import axios from 'axios';
import { defineComponent } from "vue";
import { Admin } from '@/interfaces/adminInterface';
import { useRouter } from 'vue-router';

const apiUrl = process.env.VUE_APP_API_BASE_URL; // When testing on mobile

export default defineComponent({
    data() {
        return {
            admin: [] as Admin[],
            admins: [] as Admin[],
            newAdminData: [] as Admin[],
            adminIsOk: false,
            showAddAdminModal: false,
            newAdmin: {
                nom: '',
                prenom: '',
                email: '',
                droit:0
            },
        };
    },
    created() {
        this.admin = JSON.parse(localStorage.getItem('admin') || 'null');
        if (this.admin != null) {
            this.adminIsOk = true;
            this.getAllAdmins();
        }
        else {
            const router = useRouter();
            router.push('/admin-login');
        }
    },
    methods:
    {
        async getAllAdmins() {
            try {
                const response = await axios.get(`${apiUrl}/getAllAdmins`);
                this.admins = response.data;
            } catch (error) {
                console.error('Erreur lors de la récupération des administrateurs :', error);
            }
        },
        formatDate(date: Date) {
            const formattedDate = new Date(date);
            const year = formattedDate.getFullYear();
            const month = (formattedDate.getMonth() + 1).toString().padStart(2, '0');
            const day = formattedDate.getDate().toString().padStart(2, '0');
            return `${day}/${month}/${year}`;
        },
        async deleteAdmin(adminId: number) {
            try {
                await axios.delete(`${apiUrl}/deleteAdmin/${adminId}`);
                console.log('Administrateur supprimé');
                setTimeout(() => {
                    // Actualisez les données après le délai
                    this.getAllAdmins();
                }, 1000);
            } catch (error) {
                console.error("Erreur lors de la suppression de l'administrateur :", error);
            }
        },
        async resetPassword(adminName: string, adminSurname: string, adminEmail: string, adminId: number) {
            try {
                const newPassword = this.generateRandomString();
                await axios.put(`${apiUrl}/updateAdminPassword/${adminId}`, { password: newPassword });
                console.log('Mot de passe mis à jour');
                await this.sendAdminEmailPassword(adminEmail, newPassword, adminName, adminSurname);
                setTimeout(() => {
                    // Actualisez les données après le délai
                    this.getAllAdmins();
                }, 1000);
            } catch (error) {
                console.error("Erreur lors de la modification du mot de passe de l'administrateur :", error);
            }
        },
        openAddAdminModal() {
            this.showAddAdminModal = true;
        },
        closeAddAdminModal() {
            this.showAddAdminModal = false;
            this.newAdmin = {
                nom: '',
                prenom: '',
                email: '',
                droit:0,
            };
        },
        async addAdmin() {
            try {
                const date = new Date().toISOString();
                const data = {
                    email: this.newAdmin.email,
                    password: this.generateRandomString(),
                    lastConnexionDate: date,
                    name: this.newAdmin.nom.toUpperCase(),
                    surname: this.capitalizeFirstLetter(this.newAdmin.prenom),
                    adminRight: this.newAdmin.droit,
                };

                const response = await axios.post(`${apiUrl}/addAdmin`, data);
                console.log(response.data);
                await this.sendAdminEmail(data.email, data.password, data.name, data.surname);
                this.closeAddAdminModal();
                setTimeout(() => {
                    // Actualisez les données après le délai
                    this.getAllAdmins();
                }, 1000);
            } catch (error) {
                console.error(error);
            }
        },
        generateRandomString() {
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$';
            let randomString = '';
            for (let i = 0; i < 12; i++) {
                const randomIndex = Math.floor(Math.random() * characters.length);
                randomString += characters.charAt(randomIndex);
            }
            return randomString;
        },
        capitalizeFirstLetter(str: string) {
            return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
        },
        async sendAdminEmail(receiverMail: string, receiverPassword: string, receiverName: string, receiverSurname: string) {
            try {
                const response = await axios.post(`${apiUrl}/sendAdminEmail`, { receiverMail, receiverPassword, receiverName, receiverSurname });
                console.log('E-mail envoyé avec succès :', response.data);
            } catch (error) {
                console.error('Erreur lors de l\'envoi de l\'e-mail :', error);
            }
        },
        async sendAdminEmailPassword(receiverMail: string, receiverPassword: string, receiverName: string, receiverSurname: string) {
            try {
                const response = await axios.post(`${apiUrl}/sendAdminEmailPassword`, { receiverMail, receiverPassword, receiverName, receiverSurname });
                console.log('E-mail envoyé avec succès :', response.data);
            } catch (error) {
                console.error('Erreur lors de l\'envoi de l\'e-mail :', error);
            }
        },
        confirmResetPassword(admin:any) {
            const confirmed = window.confirm(
                `Voulez-vous vraiment réinitialiser le mot de passe pour ${admin.name} ${admin.surname}?`
            );
            if (confirmed) {
                this.resetPassword(admin.name, admin.surname, admin.email, admin.id);
            } else {
                // L'utilisateur a annulé la réinitialisation du mot de passe
                console.log('Réinitialisation du mot de passe annulée.');
            }
        }
    }
});