<template>
  <footer class="footer">
    <div class="footer-images">
      <!-- Icône 1 -->
      <a href="https://www.facebook.com/occtt/" target="_blank">
        <img src="../../assets/facebookIcon.png" alt="Facebook" />
      </a>
      <router-link to="/admin-login">
        <button class="gold-button-admin">Administration</button>
      </router-link>
      <!-- Icône 2 -->
      <a href="https://www.instagram.com/occessontt/" target="_blank">
        <img src="../../assets/instagramIcon.png" alt="Instagram" />
      </a>
    </div>
    <p>Copyright © 2024 - DUVAL Elouan - Tous droits réservés</p>
  </footer>
</template>

<script src="./footer.ts"></script>
<style src="./footer.css"></style>
