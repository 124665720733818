import { defineComponent, onMounted, onBeforeUnmount, ref } from "vue";
import pdfFile from "../../assets/Reglement_tournoi_occessontt.pdf";

export default defineComponent({
  setup() {
    const pdfUrl = pdfFile;
    const isMobile = ref(false); // Utiliser un ref pour garder une réactivité sur isMobile

    // Fonction pour vérifier la taille de l'écran
    const checkIfMobile = () => {
      isMobile.value = window.innerWidth <= 767;
    };

    // Détecter si la taille de l'écran est celle d'un mobile lors du montage du composant
    onMounted(() => {
      checkIfMobile();
      window.addEventListener("resize", checkIfMobile);
    });

    // Retirer l'écouteur d'événements lors de la destruction du composant
    onBeforeUnmount(() => {
      window.removeEventListener("resize", checkIfMobile);
    });

    return {
      pdfUrl,
      isMobile,
    };
  },
});
