<template>
  <div>
    <Header/>    
    <Home/>       
    <router-view/>
    <Footer/>
  </div>
</template>

<script type="ts">
import Header from './components/header/Header.vue'
import Home from './components/home/Home.vue'
import Footer from './components/footer/Footer.vue'

export default {
  components: {
    Header,
    Footer
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body{
  margin: 0;
}
</style>