<template>
  <router-link to="/">
    <button class="gold-button">Accueil</button>
  </router-link>
  <h1>Comptes administrateurs</h1>
  <table>
    <tr>
      <th class="thAdmins">Nom</th>
      <th class="thAdmins">Prénom</th>
      <th class="thAdmins">Email</th>
      <th class="thAdmins">Dernière connexion</th>
      <th class="thAdmins">Réinitialisation mot de passe</th>
      <th class="thAdmins">Suppression</th>
      <!-- Ajoutez d'autres colonnes en fonction des propriétés de l'administrateur -->
    </tr>
    <tr v-for="admin in admins" :key="admin.id">
      <td>{{ admin.name }}</td>
      <td>{{ admin.surname }}</td>
      <td>{{ admin.email }}</td>
      <td>{{ formatDate(admin.lastConnexionDate) }}</td>
      <td>
        <a class="resetPassword" @click="confirmResetPassword(admin)">Réinitialisation du mot de passe</a>
      </td>
      <td>
        <img class="bin" src="../../../assets/bin.png" @click="deleteAdmin(admin.id)" />
      </td>
    </tr>
  </table>
  <button class="gold-button" @click="openAddAdminModal">Ajouter un administrateur</button>
  <!-- Modal d'ajout d'administrateur -->
  <div v-if="showAddAdminModal" class="modal">
    <div class="modal-content">
      <!-- Formulaire d'ajout d'administrateur -->
      <form @submit.prevent="addAdmin">
        <div class="form-field">
          <label for="prenom">Prénom </label>
          <input class="styled-input-2" type="text" id="prenom" placeholder="Prenom" v-model="newAdmin.prenom"
            required />
        </div>
        <div class="form-field">
          <label for="nom">Nom </label>
          <input class="styled-input-2" type="text" id="nom" placeholder="Nom" v-model="newAdmin.nom" required />
        </div>
        <div class="form-field">
          <label for="email">Email </label>
          <input class="styled-input-2" type="email" id="email" placeholder="Email" v-model="newAdmin.email" required />
        </div>
        <div class="form-field">
          <label for="droit">Droit</label>
          <select id="droit" v-model="newAdmin.droit" class="styled-input-2" required>
            <option value="1">Administration tournoi</option>
            <option value="2">Administration comptes</option>
          </select>
        </div>
        <div class="button-container">
          <button class="gold-button" type="submit" :disabled="newAdmin.email == ''">Valider</button>
          <button class="gold-button" @click="closeAddAdminModal">Annuler</button>
        </div>
      </form>
    </div>
  </div>
</template>

<style src="./adminAccounts.css"></style>
<script src="./adminAccounts.ts"></script>
