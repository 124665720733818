<template>
  <header class="header">
    <!-- Logo à gauche -->
    <div class="logo">
      <router-link to="/">
        <img src="../../assets/LogoCESSON.png" alt="Logo de O.C. CESSON T.T." />
      </router-link>
    </div>
  </header>
</template>

<script src="./header.ts"></script>
<style src="./header.css"></style>
