<template>
  <div class="home">
    <div class="square-container">
      <!-- Carré 1 -->
      <div class="square">
        <router-link to="/registration">
          <div class="square-content">
            <div class="title">Inscription</div>
          </div>
        </router-link>
      </div>
      <!-- Carré 2 -->
      <div class="square">
        <router-link to="/playerslist">
          <div class="square-content">
            <div class="title">Liste des inscrits</div>
          </div>
        </router-link>
      </div>
      <!-- Carré 3 -->
      <div class="square">
        <router-link to="/rules">
          <div class="square-content">
            <div class="title">Règlement</div>
          </div>
        </router-link>
      </div>
    </div>
    </div>
</template>

<script src="./home.ts"></script>
<style src="./home.css"></style>
