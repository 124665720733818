<template>
  <div>
    <router-link to="/">
      <button class="gold-button">Accueil</button>
    </router-link>
    <h1>Liste inscrits</h1>
    <button class="gold-button" @click="exportToExcel">Exporter les joueurs en Excel</button>
    <ul>
      <li>
        <h3>Samedi</h3>
        <ul>
          <li v-for="table in groupTableSaturday" :key="table.id">
            <a class="ahovergold" :href="'#table-' + table.id">{{ table.name }} - {{ table.startHour }} - {{
            table.placesNumber }} places restantes</a>
          </li>
        </ul>
      </li>
      <li>
        <h3>Dimanche</h3>
        <ul>
          <li v-for="table in groupTableSunday" :key="table.id">
            <a class="ahovergold" :href="'#table-' + table.id">{{ table.name }} - {{ table.startHour }} - {{
            table.placesNumber }} places restantes</a>
          </li>
        </ul>
      </li>
    </ul>
    <div>
      <h2>Samedi</h2>
      <table v-for="table in groupTableSaturday" :id="'table-' + table.id">
        <caption>
          {{
            table.name
          }}
          -
          {{
              table.startHour
            }}
        </caption>
        <tr>
          <th>Joueur</th>
          <th>Club</th>
          <th>Point</th>
          <th>Email</th>
          <th>Téléphone</th>
          <th>Action</th>
        </tr>
        <tr v-for="playerTable in playerTableData">
          <template v-if="playerTable.tableIds
              .split(',')
              .map((id) => parseInt(id))
              .includes(table.id)
            ">
            <td>
              {{ getPlayerName(playerTable.playerId) }}
            </td>
            <td>
              {{ getPlayerClub(playerTable.playerId) }}
            </td>
            <td>
              {{ getPlayerRank(playerTable.playerId) }}
            </td>
            <td>
              {{ getPlayerMail(playerTable.playerId) }}
            </td>
            <td>
              {{ getPlayerPhoneNumber(playerTable.playerId) }}
            </td>
            <td>
              <img class="bin" src="../../../assets/bin.png" @click="deletePlayerTable(playerTable, table)">
            </td>
          </template>
        </tr>
      </table>
      <h2>Dimanche</h2>
      <table v-for="table in groupTableSunday" :id="'table-' + table.id">
        <caption>
          {{
            table.name
          }}
          -
          {{
              table.startHour
            }}
        </caption>
        <tr>
          <th>Joueur</th>
          <th>Club</th>
          <th>Point</th>
          <th>Email</th>
          <th>Téléphone</th>
          <th>Action</th>
        </tr>
        <tr v-for="playerTable in playerTableData">
          <template v-if="playerTable.tableIds
              .split(',')
              .map((id) => parseInt(id))
              .includes(table.id)
            ">
            <td>
              {{ getPlayerName(playerTable.playerId) }}
            </td>
            <td>
              {{ getPlayerClub(playerTable.playerId) }}
            </td>
            <td>
              {{ getPlayerRank(playerTable.playerId) }}
            </td>
            <td>
              {{ getPlayerMail(playerTable.playerId) }}
            </td>
            <td>
              {{ getPlayerPhoneNumber(playerTable.playerId) }}
            </td>
            <td>
              <img class="bin" src="../../../assets/bin.png" @click="deletePlayerTable(playerTable, table)">
            </td>
          </template>
        </tr>
      </table>
    </div>
  </div>
</template>

<script src="./adminTournament.ts"></script>
<style src="./adminTournament.css"></style>
