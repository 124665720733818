import axios from 'axios';
import { defineComponent } from "vue";
import { Table } from '@/interfaces/tableInterface';
import { Player } from '@/interfaces/playerInterface';
import { PlayerTable } from '@/interfaces/playerTableInterface';

const apiUrl = process.env.VUE_APP_API_BASE_URL; // When testing on mobile

export default defineComponent({
    data() {
        return {
            players: [] as Player[], // Initialiser la variable des joueurs comme un tableau vide
            player: [] as Player[],
            tables: [] as Table[],
            table: [] as Table[],
            groupTableSaturday: [] as Table[], // Tableaux du samedi
            groupTableSunday: [] as Table[], // Tableaux du dimanche
            playerTableData: [] as PlayerTable[],
        };
    },
    methods: {
        async showInscriptions() {
            await this.getAllTables();
            await this.getAllPlayers();
            await this.getAllPlayerTable();
        },
        async getAllPlayers() {
            try {
                const response = await axios.get(`${apiUrl}/getAllPlayers`);
                this.players = response.data;
            } catch (error) {
                console.error('Erreur lors de la récupération des joueurs :', error);
            }
        },
        getPlayerName(playerId: number) {
            const player = this.players.find(player => player.id === playerId);
            return player ? `${player.name} ${player.surname}` : '';
        },
        getPlayerClub(playerId: number) {
            const player = this.players.find(player => player.id === playerId);
            return player ? player.club : '';
        },
        getPlayerRank(playerId: number) {
            const player = this.players.find(player => player.id === playerId);
            return player ? player.rank : '';
        },
        async getAllTables() {
            try {
                this.tables = [];
                this.groupTableSaturday = [];
                this.groupTableSunday = [];
                const response = await axios.get<Table[]>(`${apiUrl}/getAllTables`);
                this.tables = response.data;
                this.tables.forEach(table => {
                    if (table.day == 'S') {
                        this.groupTableSaturday.push(table);
                    }
                    if (table.day == 'D') {
                        this.groupTableSunday.push(table);
                    }
                });
            } catch (error) {
                console.error('Erreur lors de la récupération des tableaux :', error);
            }
        },
        resetAll() {
            this.players = [];
            this.player = [];
            this.tables = [];
            this.table = [];
            this.groupTableSaturday = [];
            this.groupTableSunday = [];
        },
        async getAllPlayerTable() {
            try {
                this.playerTableData = [];
                const response = await axios.get<PlayerTable[]>(`${apiUrl}/getAllPlayerTable`);
                this.playerTableData = response.data;
                this.sortPlayersByRank();
            } catch (error) {
                console.error('Erreur lors de la récupération des tableaux et des joueurs :', error);
            }
        },
        sortPlayersByRank() {
            this.playerTableData.sort((a, b) => {
                const playerARank = this.getPlayerRank(a.playerId);
                const playerBRank = this.getPlayerRank(b.playerId);

                if (typeof playerARank === 'number' && typeof playerBRank === 'number') {
                    return playerBRank - playerARank;
                }

                if (typeof playerARank === 'number') {
                    return -1; // Player A is a number, so it should come first
                }

                if (typeof playerBRank === 'number') {
                    return 1; // Player B is a number, so it should come first
                }

                // Both player ranks are not numbers, compare them as strings
                return (playerBRank as string).localeCompare(playerARank as string);
            });
        },
    },
    beforeMount(){
        this.resetAll()
        this.showInscriptions()
    }
});