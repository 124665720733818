import axios from 'axios';
import { defineComponent } from "vue";
import { Table } from '@/interfaces/tableInterface';
import { Player } from '@/interfaces/playerInterface';

const parseString = require('xml2js').parseString;

const apiUrl = process.env.VUE_APP_API_BASE_URL; // When testing on mobile

export default defineComponent({
    data() {
        return {
            licenceNumber: null, // Remplacez par le numéro de licence souhaité
            joueurInfo: null, // Pour stocker les informations du joueur
            isNumLicenceValid: true,
            inscriptionButton: true,
            showInscriptionButton: true,
            showInscription: false,
            inscriptionDone: false,
            registrationClosed: false,
            showTables: false,
            userEmail: '',
            userPhoneNumber: '',
            invalidEmail: false,
            invalidPhoneNumber: false,
            playerInfo: '',
            players: [] as Player[], // Initialiser la variable des joueurs comme un tableau vide
            player: [] as Player[],
            tables: [] as Table[],
            table: [] as Table[],
            maxSelectedPerList: 2, // Nombre maximal de cases cochées par liste
            selectedTablesSaturday: [], // Compteur pour les cases cochées du samedi
            selectedTablesSunday: [], // Compteur pour les cases cochées du dimanche
            selectedTables: [] as Table[],
            groupTableSaturday: [] as Table[], // Tableaux du samedi
            groupTableSunday: [] as Table[], // Tableaux du dimanche
            alreadySigned: false,
        };
    },
    methods: {
        checkRegistrationClosingDate(){
            const date = new Date();
            const dateCloture = new Date(2025,0O0,0O3,18,0,0,0);
            if(date>dateCloture){
                this.registrationClosed = true;
            };
        },
        async makeInscription() {
            this.inscriptionButton = false;
            this.showInscription = false;
            this.showInscriptionButton = true;
            this.inscriptionDone = false;
            this.annuler();
        },
        async getLicenceJoueur() {
            this.checkRegistrationClosingDate();
            try {
                const response = await axios.get(`${apiUrl}/getLicence`, {
                    params: {
                        licence: this.licenceNumber,
                    },
                });
                parseString(response.data, async (err: any, result: any) => {
                    if (err) {
                        console.error(err);
                    } else {
                        this.joueurInfo = null;
                        this.isNumLicenceValid = true;
                        // Vérifiez si la liste de joueurs est vide
                        if (result.liste?.licence?.length == 0 || result.liste?.licence?.length == undefined) {
                            this.isNumLicenceValid = false;
                        }
                        else if (result.liste.licence[0].validation[0] == '' || result.liste.licence[0].validation[0] == undefined) {
                            this.isNumLicenceValid = false;
                            this.joueurInfo = null;
                        }
                        if (this.isNumLicenceValid) {
                            try {
                                const response = await axios.get(`${apiUrl}/getPlayerByLicence/${this.licenceNumber}`);
                                if (response.data != null) {
                                    this.alreadySigned = true;
                                } else {
                                    this.joueurInfo = result;
                                    this.showTables = true;
                                    this.getAllTables();
                                    this.resetSelectedTables();

                                    const playerInfo = result.liste.licence[0];

                                    this.player = [
                                        {
                                            licence: playerInfo.licence[0],
                                            name: playerInfo.nom[0],
                                            surname: playerInfo.prenom[0],
                                            club: playerInfo.nomclub[0],
                                            rank: playerInfo.point[0],
                                            category: playerInfo.cat[0],
                                            mail: '',
                                            sex: playerInfo.sexe[0],
                                            phone: '',
                                        }
                                    ];
                                }
                            } catch (error) {
                                console.error('Erreur lors de la récupération du joueur :', error);
                            }
                        }
                        else {
                            this.showTables = false;
                            this.resetSelectedTables();
                        }
                    }
                });
            }
            catch (error) {
                console.error(error);
                this.isNumLicenceValid = false;
            }
        },
        //Méthode qui appelle une API public donc si besoin retourner dessus, mais ne contient pas l'information sexe de la licence
        async getJoueurInfo() {
            try {
                const response = await axios.get(`${apiUrl}/getJoueurInfo`, { // Remplacez l'URL par celle de votre serveur Node.js
                    params: {
                        licence: this.licenceNumber,
                    },
                });

                // Utilisez parseString pour convertir l'XML en objet JavaScript
                parseString(response.data, (err: any, result: any) => {
                    if (err) {
                        console.error(err);
                    } else {
                        // Vérifiez si la liste de joueurs est vide
                        this.isNumLicenceValid = !!result.liste?.joueur?.length;
                        this.joueurInfo = result;

                        if (this.isNumLicenceValid) {
                            this.showTables = true;
                            this.resetSelectedTables();
                        }
                        else {
                            this.showTables = false;
                            this.resetSelectedTables();
                        }
                    }
                });
            } catch (error) {
                console.error(error);
                this.isNumLicenceValid = false;
            }
        },
        annuler() {
            // Réinitialisez les valeurs
            this.licenceNumber = null;
            this.joueurInfo = null;
            this.userEmail = '';
            this.userPhoneNumber = '';
            this.isNumLicenceValid = true; // Réinitialisez la validité du numéro de licence
            this.showTables = false;
            this.resetSelectedTables();
        },
        handleCheckboxClick(selectedList: any, maxSelected: number, playerSex: string, tableId: string, tableName: string) {
            // Vérifie si le tableau cliqué est le tableau "Dames"
            if (tableName === 'Dames') {
                return; // Ne fait rien si c'est le tableau "Dames"
            }

            // Compte le nombre de tableaux non "Dames" sélectionnés
            const nonFemininesTables = selectedList.filter((id: string) => {
                const table = this.groupTableSaturday.find((t: any) => t.id === id) ||
                    this.groupTableSunday.find((t: any) => t.id === id);
                return table && table.name !== 'Dames';
            });

            if (nonFemininesTables.length >= maxSelected) {
                // Empêche de cocher davantage de cases si le nombre maximal est dépassé
                selectedList.shift(); // Retire la case cochée la plus ancienne (non "Dames")
            }
        },
        resetSelectedTables() {
            this.selectedTablesSaturday = [];
            this.selectedTablesSunday = [];
            this.selectedTables = [];
        },
        validateEmail() {
            // Expression régulière pour valider le format de l'e-mail
            const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

            if (!emailPattern.test(this.userEmail)) {
                this.invalidEmail = true;
            } else {
                this.invalidEmail = false;
            }
        },
        validatePhoneNumber() {
            // Expression régulière pour valider le format du numéro de téléphone français
            const telephonePattern = /^(0[1-9](\s?\d{2}){4})$/;
            if (!telephonePattern.test(this.userPhoneNumber)) {
                this.invalidPhoneNumber = true;
            } else {
                this.invalidPhoneNumber = false;
            }
        },
        async getAllPlayers() {
            try {
                const response = await axios.get(`${apiUrl}/getAllPlayers`);
                this.players = response.data;
            } catch (error) {
                console.error('Erreur lors de la récupération des joueurs :', error);
            }
        },
        async getPlayer(licence: number) {
            try {
                const response = await axios.get(`${apiUrl}/getPlayerByLicence/${licence}`);
                this.player = response.data;
                console.log('Joueur récupéré :', this.player);
            } catch (error) {
                console.error('Erreur lors de la récupération du joueur :', error);
            }
            return this.player;
        },
        async getPlayerId(licence: number) {
            let playerId = null;
            try {
                const response = await axios.get(`${apiUrl}/getPlayerId/${licence}`);
                playerId = response.data;
                console.log('Joueur récupéré');
            } catch (error) {
                console.error('Erreur lors de la récupération du joueur :', error);
            }
            return playerId;
        },
        async addPlayer(newPlayer: any) {
            try {
                const response = await axios.post(`${apiUrl}/addPlayer`, JSON.stringify(newPlayer[0]), {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                console.log(response.data); // Cette ligne affiche la réponse du serveur
            } catch (error) {
                console.error(error);
            }
        },
        async getTable(id: number) {
            let tableGetById;
            try {
                const response = await axios.get(`${apiUrl}/getTable/${id}`);
                tableGetById = response.data;
                console.log('Joueur récupéré :', this.player);
            } catch (error) {
                console.error('Erreur lors de la récupération du joueur :', error);
            }
            return tableGetById;
        },
        async getAllTables() {
            try {
                this.tables = [];
                this.groupTableSaturday = [];
                this.groupTableSunday = [];
                const response = await axios.get<Table[]>(`${apiUrl}/getAllTables`);
                this.tables = response.data;
                this.tables.forEach(table => {
                    if (table.day == 'S') {
                        this.groupTableSaturday.push(table);
                    }
                    if (table.day == 'D') {
                        this.groupTableSunday.push(table);
                    }
                });
            } catch (error) {
                console.error('Erreur lors de la récupération des tableaux :', error);
            }
        },
        async addPlayerTable(playerId: number, tableId: number) {
            try {
                const data = {
                    playerId: playerId,
                    tableId: tableId
                }

                const response = await axios.post(`${apiUrl}/addPlayerTable`, JSON.stringify(data), {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                console.log(response.data);
            } catch (error) {
                console.error(error);
            }
        },
        async validateInscription() {
            try {
                this.player[0].mail = this.userEmail;
                this.player[0].phone = this.userPhoneNumber;
                await this.addPlayer(this.player);
                const playerId = await this.getPlayerId(this.player[0].licence);

                for (let i = 0; i < this.selectedTablesSaturday.length; i++) {
                    const table = await this.getTable(this.selectedTablesSaturday[i]);
                    this.selectedTables.push(table);
                }

                for (let i = 0; i < this.selectedTablesSunday.length; i++) {
                    const table = await this.getTable(this.selectedTablesSunday[i]);
                    this.selectedTables.push(table);
                }

                let tablesSelected: number[] = [];
                this.selectedTablesSaturday.forEach(tableSaturday => {
                    if (tableSaturday != null) {
                        tablesSelected.push(tableSaturday)
                    }
                });
                this.selectedTablesSunday.forEach(tableSunday => {
                    if (tableSunday != null) {
                        tablesSelected.push(tableSunday)
                    }
                });

                tablesSelected.forEach(async tableIdToAdd => {
                    if (playerId.id != null && tableIdToAdd != null) {
                        await this.addPlayerTable(playerId.id, tableIdToAdd);
                        await this.updatePlacesNumber(tableIdToAdd);
                    }
                });
                this.inscriptionDone = true;
                await this.sendMail(this.selectedTables);
            }
            catch (error) {
                console.error("Erreur lors de l'ajout du joueur :", error);
            }
        },
        async sendEmail(receiverMail: string, tableForMessage: any) {
            try {
                const tableHtml = tableForMessage.map((table: any) => {
                    return `<li>Tableau : ${table.name}</li>`;
                }).join('');
                const response = await axios.post(`${apiUrl}/sendEmail`, { receiverMail, tableHtml });
                console.log('E-mail envoyé avec succès :', response.data);
            } catch (error) {
                console.error('Erreur lors de l\'envoi de l\'e-mail :', error);
            }
        },
        sendMail(tableToShow: any) {
            if (this.inscriptionDone) {
                this.sendEmail(this.player[0].mail, tableToShow);
                this.showInscription = false;
            }
        },
        async updatePlacesNumber(tableIdToReducePlaces: number) {
            try {
                await axios.put(`${apiUrl}/decreaseTablePlaces/${tableIdToReducePlaces}`);
            } catch (error) {
                console.error(error);
            }
        },
        resetAll() {
            this.licenceNumber = null;
            this.joueurInfo = null;
            this.isNumLicenceValid = true;
            this.inscriptionButton = true;
            this.showInscriptionButton = true;
            this.showInscription = false;
            this.inscriptionDone = false;
            this.showTables = false;
            this.userEmail = '';
            this.userPhoneNumber = '';
            this.invalidEmail = false;
            this.invalidPhoneNumber = false;
            this.playerInfo = '';
            this.players = [];
            this.player = [];
            this.tables = [];
            this.table = [];
            this.maxSelectedPerList = 2;
            this.selectedTablesSaturday = [];
            this.selectedTablesSunday = [];
            this.selectedTables = [];
            this.groupTableSaturday = [];
            this.groupTableSunday = [];
            this.resetSelectedTables();
        },
    },
});
