<template>
  <div>
    <!-- Contenu de la vue PlayerList -->
    <router-link to="/">
      <button class="gold-button">Accueil</button>
    </router-link>
    <!-- Liste des liens d'ancrage pour les tableaux -->
    <div>
      <h2>Tableaux</h2>
      <ul>
        <li>
          <h3>Samedi</h3>
          <ul>
            <li v-for="table in groupTableSaturday" :key="table.id">
              <a class="ahovergold" :href="'#table-' + table.id">{{ table.name }} - {{ table.startHour }} - {{ table.placesNumber }} places restantes</a>
            </li>
          </ul>
        </li>
        <li>
          <h3>Dimanche</h3>
          <ul>
            <li v-for="table in groupTableSunday" :key="table.id">
              <a class="ahovergold" :href="'#table-' + table.id">{{ table.name }} - {{ table.startHour }} - {{ table.placesNumber }} places restantes</a>
            </li>
          </ul>
        </li>
      </ul>
    </div>

    <!-- Samedi -->
    <div>
      <h2>Samedi</h2>
      <table v-for="table in groupTableSaturday" :key="table.id" :id="'table-' + table.id">
        <caption>
          {{ table.name }} - {{ table.startHour }}
        </caption>
        <thead>
          <tr>
            <th>Joueur</th>
            <th>Club</th>
            <th>Point</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="playerTable in playerTableData" :key="playerTable.playerId">
            <template v-if="playerTable.tableIds.split(',').map((id) => parseInt(id)).includes(table.id)">
              <td>{{ getPlayerName(playerTable.playerId) }}</td>
              <td>{{ getPlayerClub(playerTable.playerId) }}</td>
              <td>{{ getPlayerRank(playerTable.playerId) }}</td>
            </template>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Dimanche -->
    <div>
      <h2>Dimanche</h2>
      <table v-for="table in groupTableSunday" :key="table.id" :id="'table-' + table.id">
        <caption>
          {{ table.name }} - {{ table.startHour }}
        </caption>
        <thead>
          <tr>
            <th>Joueur</th>
            <th>Club</th>
            <th>Point</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="playerTable in playerTableData" :key="playerTable.playerId">
            <template v-if="playerTable.tableIds.split(',').map((id) => parseInt(id)).includes(table.id)">
              <td>{{ getPlayerName(playerTable.playerId) }}</td>
              <td>{{ getPlayerClub(playerTable.playerId) }}</td>
              <td>{{ getPlayerRank(playerTable.playerId) }}</td>
            </template>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
  
<script src="./playerList.ts"></script>
<style src="./playerList.css"></style>
