import axios from "axios";
import { defineComponent } from "vue";
import { Admin } from "@/interfaces/adminInterface";

const apiUrl = process.env.VUE_APP_API_BASE_URL; // When testing on mobile

export default defineComponent({
    data() {
        return {
            formData: {
                email: '',
                password: '',
            },
            invalidEmail: false,
            invalidAccount: false,
            admin: [] as Admin[],
            isAdmin: false
        };
    },
    methods: {
        async login() {
            await this.getAdmin(this.formData.email, this.formData.password);
            // Réinitialisez les champs après la soumission du formulaire (si nécessaire)
            this.formData.email = '';
            this.formData.password = '';
        },
        validateEmail() {
            // Expression régulière pour valider le format de l'e-mail
            const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

            if (!emailPattern.test(this.formData.email)) {
                this.invalidEmail = true;
            } else {
                this.invalidEmail = false;
            }
        },
        async getAdmin(email: string, password: string) {
            this.admin = [];
            try {
                const response = await axios.get(`${apiUrl}/getAdmin`, {
                    params: {
                        email: email,
                        password: password,
                    },
                });
                if (response.data != null) {
                    this.admin = response.data;
                    console.log('Admin récupéré :', this.admin);
                    this.isAdmin = true;
                    this.invalidAccount = false;
                    // Stockez la valeur de this.admin dans le stockage local
                    localStorage.setItem('admin', JSON.stringify(this.admin));
                    const date = new Date();
                    await this.updateAdminConnexionDate(response.data.id, date);
                } else {
                    this.isAdmin = false;
                    this.invalidAccount = true;
                }
            } catch (error) {
                console.error("Erreur lors de la récupération de l'administrateur :", error);
            }
        },
        async updateAdminConnexionDate(id: any, date: Date) {
            try {
                const lastConnexionDate = date.toISOString();
                await axios.put(`${apiUrl}/updateAdminConnexionDate/${id}`, { lastConnexionDate: lastConnexionDate });
            } catch (error) {
                console.error(error);
            }
        }
    },
});