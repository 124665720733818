<template>
  <div>
    <router-link to="/">
      <button class="gold-button">Accueil</button>
    </router-link>
    <h1>Administration</h1>
    <form @submit.prevent="login">
      <div class="form-group">
        <label for="email">Email : </label>
        <input class="styled-input"
          type="email"
          id="email"
          v-model="formData.email"
          @input="validateEmail"
          placeholder="Entrez votre adresse mail"
          required
        />
        <p v-if="invalidEmail" class="error-message">
          L'adresse e-mail n'est pas valide.
        </p>
      </div>
      <div class="form-group">
        <label for="password">Mot de passe : </label>
        <input class="styled-input"
          type="password"
          id="password"
          v-model="formData.password"
          placeholder="Entrez votre mot de passe"
          required
        />
      </div>
      <button
        class="gold-button"
        type="submit"
        :disabled="
          formData.email === null ||
          formData.email === '' ||
          formData.password === null ||
          formData.password === '' ||
          invalidEmail
        "
      >
        Se connecter
      </button>
    </form>
    <div v-if="isAdmin">
      <p>
        Bienvenue {{ admin.name }} {{ admin.surname }} <br />
      </p>
      <router-link to="/admin-tournoi"
        ><button class="gold-button" :disabled="admin.adminRight < 1">
          Administration tournoi
        </button></router-link
      ><br />
      <router-link to="/admin-accounts"
        ><button class="gold-button" :disabled="admin.adminRight < 2">
          Administration comptes
        </button></router-link
      >
    </div>
    <p v-if="invalidAccount" class="error-message">
      Adresse email ou mot de passe incorrect
    </p>
  </div>
</template>

<script src="./adminLogin.ts"></script>
<style src="./adminLogin.css"></style>
