import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../components/home/Home.vue'
import NotFound from '../components/notFound/NotFound.vue'
import AdminLogin from '../components/admin/adminLogin/AdminLogin.vue'
import AdminTournament from '../components/admin/adminTournament/AdminTournament.vue'
import AdminAccounts from '../components/admin/adminAccounts/AdminAccounts.vue'
import PlayersList from '../components/playersList/PlayerList.vue'
import Registration from '../components/registration/Registration.vue'
import Rules from '../components/rules/Rules.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: { name: 'home' }
  },
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/:catchAll(.*)',
    redirect: { name: 'notfound' }
  },
  {
    path: '/notfound',
    name: 'notfound',
    component: NotFound
  },
  {
    path: '/admin-login',
    name: 'adminLogin',
    component: AdminLogin
  },
  {
    path: '/admin-tournoi',
    name: 'adminTournament',
    component: AdminTournament
  },
  {
    path: '/registration',
    name: 'Registration',
    component: Registration
  },
  {
    path: '/playerslist',
    name: 'PlayersList',
    component: PlayersList
  },
  {
    path: '/rules',
    name: 'Rules',
    component: Rules
  },
  {
    path: '/admin-accounts',
    name: 'AdminAccounts',
    component: AdminAccounts
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
