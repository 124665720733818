<template>
  <div>
    <!-- Contenu de la vue Rules -->
    <router-link to="/">
      <button class="gold-button">Accueil</button>
    </router-link>
    <div class="global-container">
      <!-- Image cliquable -->
      <div>
        <img class="clickable-image" src="../../assets/Affiche_tournoi_national.jpg" alt="Affiche">
      </div>
      <div class="pdf-container">
        <object v-if="!isMobile" :data="pdfUrl" type="application/pdf">
          <a :href="pdfUrl"><button class="gold-button">Télécharger le réglement</button></a>
        </object>
        <a v-else :href="pdfUrl"><button class="gold-button">Télécharger le réglement</button></a>
      </div>
    </div>
  </div>
</template>

<script src="./rules.ts"></script>
<style src="./rules.css"></style>